import { Suspense } from "react";

// Routing
import { Routes, Navigate, Route } from "react-router-dom";
// Components
import { Main } from "./components";
import {
  MainPage,
  PrivacyPolicyPage,
  TermsOfUsePage,
  TherapistFormPage,
  ThankYouPage,
  JoinOurNetworkPage,
} from "./pages";
import { SchoolRoot } from "pages/SchoolPage/SchoolRoot";
import { TalkToAdvisor } from "pages/SchoolPage/TalkToAdvisor/TalkToAdvisor";
import { FormSuccessPage } from "pages/SchoolPage/FormSuccessPage/FormSuccessPage";
import { routes } from "constants/index";
import { Container } from "components/Core";

import { CircularProgress } from "@mui/material";
import { Courses } from "pages/Courses/Courses";
import { CourseDetail } from "pages/Courses/CourseDetail";

const App = () => {
  return (
    <Suspense
      fallback={
        <Container height={["100vh"]}>
          <CircularProgress />
        </Container>
      }
    >
      <Routes>
        <Route
          path="/"
          element={<Main header={true} consultButton={true} footer={true} />}
        >
          <Route index element={<MainPage />} />
        </Route>

        <Route
          path="/join-us"
          element={<Main header={true} consultButton={true} footer={false} />}
        >
          <Route index element={<TherapistFormPage />} />
        </Route>

        <Route
          path="/privacy-policy"
          element={<Main header={false} consultButton={true} footer={false} />}
        >
          <Route index element={<PrivacyPolicyPage />} />
        </Route>

        <Route
          path="/terms-of-use"
          element={<Main header={false} consultButton={true} footer={false} />}
        >
          <Route index element={<TermsOfUsePage />} />
        </Route>

        <Route
          path="/thank-you"
          element={<Main header={true} consultButton={true} footer={true} />}
        >
          <Route index element={<ThankYouPage />} />
        </Route>

        <Route
          path="/join-our-network"
          element={<Main header={true} consultButton={false} footer={true} />}
        >
          <Route index element={<JoinOurNetworkPage />} />
        </Route>
        <Route
          path="/courses"
          element={<Main header={true} consultButton={false} footer={true} />}
        >
          <Route index element={<Courses />} />
        </Route>
        <Route
          path="/course/:courseId"
          element={<Main header={true} consultButton={false} footer={true} />}
        >
          <Route index element={<CourseDetail to="/" />} />
        </Route>
        <Route
          path={routes.school}
          element={
            <Main
              hideGetStarted
              header
              consultButton={false}
              footer
              hideSchoolNav
              languageBlockDisplayMediaQuery={["none", "none", "block"]}
              // hideSignIn
            />
          }
        >
          <Route index element={<SchoolRoot />} />
        </Route>
        <Route
          path={routes.talkToAdvisor}
          element={
            <Main
              navigateTo={routes.school}
              header={true}
              consultButton={false}
              footer={true}
              hideGetStarted
              hideSchoolNav
            />
          }
        >
          <Route index element={<TalkToAdvisor />} />
        </Route>
        <Route
          path={routes.talkToAdvisorSuccess}
          element={
            <Main
              navigateTo={routes.school}
              header={true}
              consultButton={false}
              hideGetStarted
              hideSchoolNav
            />
          }
        >
          <Route index element={<FormSuccessPage />} />
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
};

export default App;
